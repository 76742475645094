import { Component, Prop } from 'vue-property-decorator';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import StudioPageBase from '@/support/studioPageBase';
import Util from '@/support/utility';
import { ServerDataModule } from '@/store/serverData';
import { EventBus } from '@/support/eventBus';
import { IPermissionDto } from '@/interfaces';

@Component
export default class RemovePermissionComponent extends StudioPageBase {
    options = {
    };

    get serverData() {
        return ServerDataModule.serverData;
    }

    key = 'removePermission';
    permission = Object.assign({}, Globals.NewPermission);
    saveDisabled: boolean = true;

    busy = false;

    @Prop({ default: <any>'auto' })
    height: string;

    created() {
        Debug.setDebugModule('RemovePermission', this);

        super.created('removePermission', true);
    }

    mounted() {
        super.mounted();
        this.permission = Object.assign({}, Globals.NewPermission);
}

    show(permission: IPermissionDto) {
        this.permission = Object.assign({}, permission);
        this.saveDisabled = true;
        (<any>this.$modal).show(this.key);
    }

    hide() {
        this.busy = false;
        this.saveDisabled = true;
        this.permission = Object.assign({}, Globals.NewPermission);
        (<any>this.$modal).hide(this.key);
    }

    async done() {
        try {
            this.busy = true;
            return await this.removePermission(Globals.EveryoneAtMicrosoft);
        } finally {
            this.hide();
        }
    }

    cancel() {
        this.hide();
    }

    async removePermission(title: string) {
        try {
            let response = await PlayerApi.removePermission(this.studio.studio, this.permission);

            EventBus.Instance.$emit(EventBus.PermissionsChanged, response);

            Util.showToast('Added ' + title);
            return response;
        } catch (err) {
            let message = err.message || 'ERROR';
            Util.showError('removePermission failed to add permission error: ' + message);
            return null;
        }
    }

}
